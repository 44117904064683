import { gsap, Elastic, Power1, TweenMax, Power3 } from "gsap";

document.addEventListener('DOMContentLoaded',function() {

    const el = document.getElementsByClassName('js-accordion'),
          el_it = document.querySelectorAll('.js-it_accordion');
    
    const init = function(obj) {

        const item = obj.getElementsByClassName('js-item');
        
        const toggle = function(ev, section, op) {
            TweenMax.to(section, 0, {
                height: ev,
                immediateRender: false,
                onComplete: function() {
                    if (window.swiperFaq) {
                        window.swiperFaq.updateAutoHeight(400);
                    }
                }
            });
            
            TweenMax.to(section, 1, {
                opacity: op,
                ease: Power3.easeOut
            });            
        }

        const action = function(e) {
            const _that = e.currentTarget,
                  _height = 0;
                        
            if (_that.classList.contains('is-rollout')) {
                _that.classList.remove('is-rollout');
                toggle(_height, _that.getElementsByClassName('js-section')[0], 0);
            } else {            
                for (let i = 0; i < item.length; i ++) {
                    item[i].classList.remove('is-rollout');
                    toggle(_height, item[i].getElementsByClassName('js-section')[0], 0);
                }   
                _that.classList.add('is-rollout');
                toggle('auto', _that.getElementsByClassName('js-section')[0], 1);
            }
        };
        
        for( let i = 0; i < item.length; i ++ ) {
            item[i].addEventListener('click', action);
            
            if (item[i].classList.contains('is-rollout')) {
                toggle('auto', item[i].getElementsByClassName('js-section')[0], 1);
            }
        }
    };
    
    window.accordion_it = (obj, mobile_only) => {
        let item = obj.querySelectorAll('.js-item'),
            mediaQuery = '(max-width: 640px)',
            mediaQueryList = window.matchMedia(mediaQuery),
            mediaQueryPositive = '(max-width: 1024px)',
            mediaQueryListPositive = window.matchMedia(mediaQueryPositive),
            tl;
  
        const toggle = function(ev, section, op) {
            tl = gsap.timeline({clearProps:"all"});
            
            tl.to(section, {
                height: ev,
                duration: .25,
                ease: Power1.easeOut
            })
            .fromTo(section, .5, {
                opacity: 0,
                y: 20,
                ease: 'elastic.out(1, .8)',
            }, {
                opacity: 1,
                y: 0,
                ease: 'elastic.out(1, .8)',
            })
        }

        const action = (e) => {
            const _that = e.currentTarget,
                  _height = 0;
                        
            if (_that.classList.contains('is-rollout')) {
                _that.classList.remove('is-rollout');
                toggle(_height, _that.getElementsByClassName('js-section')[0], 0);                
                
            } else {
                for (let i = 0; i < item.length; i ++) {
                    item[i].classList.remove('is-rollout');
                    toggle(_height, item[i].getElementsByClassName('js-section')[0], 0);
                }   
    
                _that.classList.add('is-rollout');
                toggle('auto', _that.getElementsByClassName('js-section')[0], 1);
            }
        };

        const start = () => {  
            for( let i = 0; i < item.length; i ++ ) {    
                item[i].addEventListener('click', action);
                if (item[i].classList.contains('is-rollout')) {
                    toggle('auto', item[i].getElementsByClassName('js-section')[0], 1);
                }
            }        
        }
        
        const destroy = () => {
            tl ? tl.kill(1,1) : false;

            for( let i = 0; i < item.length; i ++ ) {    
                item[i].removeEventListener('click', action);
                item[i].querySelector('.js-section').removeAttribute('style')
            }
        }
        
        if (obj.classList.contains('o-accordion--benefits')) {
            mediaQueryList.addEventListener('change', event => {
                event.matches ? start() : destroy();
            })
            mediaQueryList.matches ? start() : false;
        } else if (obj.classList.contains('o-accordion--positive')) {
            mediaQueryListPositive.addEventListener('change', event => {
                event.matches ? start() : destroy();
            })
            mediaQueryListPositive.matches ? start() : false;
        } else {
            start();
        }
    };

    if (el.length > 0) {
        for (let i = 0; i < el.length; i++) {
            init(el[i]);
        }
    }
    
    if (el_it.length > 0) {
        for (let i = 0; i < el_it.length; i++) {
            accordion_it(el_it[i]);
        }
    }
    
    //el_it ? accordion_it(el_it, 1) : false;

}, false);