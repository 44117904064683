import { gsap, Elastic, Power1 } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

document.addEventListener('DOMContentLoaded',function() {
    gsap.registerPlugin(ScrollTrigger);
    
    window.animsInit = function() {

        const it_hero = document.querySelector('.c-it_hero');
        if (it_hero) { 

            const textWrapper1 = it_hero.querySelector('.char1');
            textWrapper1.innerHTML = textWrapper1.textContent.replace(/\S/g, "<b class='char'>$&</b>");
            
/*
            const textWrapper1_5 = it_hero.querySelector('.char1_5');
            textWrapper1_5.innerHTML = textWrapper1_5.textContent.replace(/\S/g, "<b class='char'>$&</b>");
*/
            
            const textWrapper2 = it_hero.querySelector('.char2');
            textWrapper2.innerHTML = textWrapper2.textContent.replace(/\S/g, "<b class='char'>$&</b>");
            
/*
            const textWrapper2_5 = it_hero.querySelector('.char2_5');
            textWrapper2_5.innerHTML = textWrapper2_5.textContent.replace(/\S/g, "<b class='char'>$&</b>");
*/
            
            const textWrapper3 = it_hero.querySelector('.char3');
            textWrapper3.innerHTML = textWrapper3.textContent.replace(/\S/g, "<b class='char'>$&</b>");
            
            let chars1 = textWrapper1.querySelectorAll('.char'),
                chars2 = textWrapper2.querySelectorAll('.char'),
                chars3 = textWrapper3.querySelectorAll('.char');
        
            let tl = gsap.timeline().delay(1);
            
            const cfrom = {
                //y: 20,
                scaleX: 2.2,
                scaleY: 2.2,
                autoAlpha: 0,
            }
            
            const cto = {
                autoAlpha: 1,
                stagger: { 
                from: "random", 
                    amount: 1
                },
                duration: 1,
                scaleX: 1,
                scaleY: 1,
                onStart: function(e) {
                    this._targets[0].parentNode.classList.add('glitch');
                },
                ease: 'elastic.out(1, .8)',
            }
            
            tl            
            .from(it_hero.querySelector('.girls'), {
                duration: 1.2,
                x: -20,
                autoAlpha: 0,
                ease: 'elastic.out(1, .8)',
            })
            
/*
            .to(document.querySelector('.o-dora--global .o-dora__hide'), {
                duration: 1.2,
                scale: 1.1,
                opacity: .8,
                ease: 'power1.out'
            }, '-=.6')
*/
            
            .fromTo(
                chars1, cfrom, cto, '-=1.2',
            )
/*
            
            .fromTo(
                chars1_5, cfrom, cto, '-=1.5'
            )
            
*/
            .fromTo(
                chars2, cfrom, cto, '-=1.5',
            )
/*
            
            .fromTo(
                chars2_5, cfrom, cto, '-=1.5'
            )
            
*/
            .fromTo(
                chars3, cfrom, cto, '-=1.7'
            )
            
            .from(it_hero.querySelector('.o-btn'), {
                duration: 1.2,
                x: 40,
                autoAlpha: 0,
                ease: 'elastic.out(1, .8)',
            }, '-=2.5')
            
            .from(document.querySelector('.c-it_nav'), {
                duration: 1.5,
                y: 40,
                autoAlpha: 0,
                ease: 'elastic.out(1, .8)',
            }, '-=4.6')
            
            .from(document.querySelector('.c-it_navbar'), {
                duration: 1.5,
                yPercent: 50,
                autoAlpha: 0,
                ease: 'elastic.out(1, .8)',
            }, '-=3.7')
            
            .from(it_hero.querySelector('.o-scrolldown'), {
                duration: 1.5,
                y: -40,
                autoAlpha: 0,
                ease: 'elastic.out(1, .8)',
            }, '-=4.7')

            .from(document.querySelector('.c-it_topbar'), {
                duration: 1.5,
                yPercent: -10,
                autoAlpha: 0,
                ease: 'elastic.out(1, .8)',
            }, '-=4.7')            
        }

        if (document.body.classList.contains('page-it') && document.body.classList.contains('subsite')) {
            let tl = gsap.timeline().delay(1);
            
            tl
            .from(document.querySelector('.c-it_topbar'), {
                duration: 1.5,
                yPercent: -10,
                autoAlpha: 0,
                ease: 'elastic.out(1, .8)',
            })
            
            tl.from(document.querySelector('.c-it_nav'), {
                duration: 1.5,
                y: 40,
                autoAlpha: 0,
                ease: 'elastic.out(1, .8)',
            }, '-=1.5')
            
            .from(document.querySelector('.c-it_navbar ul'), {
                duration: 1.5,
                yPercent: 50,
                autoAlpha: 0,
                ease: 'elastic.out(1, .8)',
            }, '-=1.7')
        }
        
        const it_departments__list = document.querySelector('.c-it_departments__list');
        if (it_departments__list) {
            const blocks = it_departments__list.querySelectorAll('.o-it_department');
            const tl = gsap.timeline().delay(.1).eventCallback("onComplete", () => {
                  })

            .from(blocks, {
                y: 50,
                opacity: 0,
                stagger: {
                    each: 0.1
                }
            }); 
        }
        
        const it_hire__list = document.querySelector('.c-it_hire');
        if (it_hire__list) {
            const blocks = it_hire__list.querySelectorAll('.swiper-slide');
            const tl = gsap.timeline().delay(.1),
                  description = document.querySelector('.c-it_hire__description');

            tl.from(blocks, {
                y: 50,
                opacity: 0,
                stagger: {
                    each: 0.1
                }
            })
            .from(description, {
                x: 50,
                opacity: 0,
            })
        }

/*
        if (document.querySelector('.workers--full')) {
            ScrollTrigger.matchMedia({
                "(min-width: 641px)": ()=> {
                    ScrollTrigger.create({
                        trigger: '.workers--full',
                        pin: true,
                        start: 'top-=100px top',
                        end: '+=500px'
                    });
                }
            })
        }
*/
        
        const areas = function() {
            ScrollTrigger.batch(".c-areas .c-areas__nav li", {
                onEnter: batch => gsap.to(batch, {opacity: 1, y: 0, stagger: {each: 0.15, grid: [1, 3]}, overwrite: true}),
                onLeave: batch => gsap.set(batch, {opacity: 0, y: -100, overwrite: true}),
                onEnterBack: batch => gsap.to(batch, {opacity: 1, y: 0, stagger: 0.15, overwrite: true}),
                onLeaveBack: batch => gsap.set(batch, {opacity: 0, y: 100, overwrite: true})
            });
        };

        const numbers = function() {
            ScrollTrigger.batch(".c-numbers .o-item", {
                onEnter: batch => gsap.to(batch, {opacity: 1, y: 0, stagger: {each: 0.15, grid: [1, 3]}, overwrite: true}),
/*
                onLeave: batch => gsap.set(batch, {opacity: 0, y: -100, overwrite: true}),
                onEnterBack: batch => gsap.to(batch, {opacity: 1, y: 0, stagger: 0.15, overwrite: true}),
                onLeaveBack: batch => gsap.set(batch, {opacity: 0, y: 100, overwrite: true})
*/
            });
        };

        if (document.querySelector('.a-parallax')) {
            gsap.to(".a-parallax", {
              yPercent: 20,
              ease: "none",
              scrollTrigger: {
                trigger: ".a-parallax",
                scrub: true,
                start: 'top top'
              }, 
            });
        }
        
        const hero = function() {
            let tl, reorient = false;

            function createAnim() {
                tl = gsap.timeline({
                    clearProps: 'all'
                }).delay(1);
            
                tl.
                    from('.c-hero .o-wrap', 1, { 
                        autoAlpha: 0,
                        height: 0,
                    }).
    
                    from('.c-hero', 1, { 
                        paddingBottom: 0,
                    }, '-=1').
                    
                    from('.c-numbers', 1, { 
                        scaleY: 1.8,
                    }, '-=1').
                    
                    call(function() {
                        if (reorient === false) {
                            startAnimms();
                            console.log('play anims')
                        }
                    }).
                                   
                    to('.c-hero .o-home svg', 0.5, { 
                        fill: 'white'
                    }).
                    
                    to('.c-numbers .o-article', 0.5, { 
                        autoAlpha: 1,
                    }, '-=.5').
                    
                    to('.c-numbers__swiper', 0.5, { 
                        autoAlpha: 1,
                    }, '-=.5').
    
                    to('.c-hero .o-home svg', 0.5, { 
                        opacity: 0
                    }, '+=.5').
    
                    to('.c-hero .o-cols .o-cols__item', 0.5, { 
                        opacity: 1,
                        autoAlpha: 1,
                    }, '-=.5').
                    
                    call(function() {
                        document.querySelector('.c-hero .o-home').style.display = 'none';                    
                        //window.videoWidget.toggle('auto', document.querySelector('.js-videowidget').querySelector('.js-content'), 1);
                    })
            }
                
            createAnim();
            
            window.addEventListener("orientationchange", function(event) {                
                if (tl) {
                    reorient = true;
                   
                    setTimeout(function() {
                        tl.time(0).kill(true);
                        gsap.set(".c-hero", { clearProps: true });
                        gsap.set(".c-hero .o-home svg", { clearProps: true });
                        gsap.set(".c-hero .o-wrap", { clearProps: true });
                        gsap.set(".c-hero .o-cols .o-cols__item", { clearProps: true });
                        gsap.set(".c-numbers", { clearProps: true });
                        gsap.set(".c-numbers__swiper", { clearProps: true });
                        document.querySelector('.c-hero .o-home').removeAttribute('style')
                    }, 1)
                    
                    setTimeout(function() {
                        createAnim();
                        console.warn('start again')
                    }, 2);     
                    
                    ScrollTrigger.refresh();              
                }
            });
            
            const ref = ()=> {
                ScrollTrigger.refresh();
            }

            window.addEventListener('resize', ref);

/*
            gsap.to(".o-cols__item--article", {
                yPercent: 10,
                ease: "none",
                scrollTrigger: {
                    trigger: ".o-cols__item--article",
                    scrub: true,
                }, 
            });
*/
        }

        const life = function() {
            gsap.from('.js-lifeSwiper', {
                scrollTrigger: {
                    trigger: '.js-lifeSwiper',
                    scrub: 2.2,
                    start: 'top bottom',
                    end: 'top center'
                },      
                yPercent: 25
            });
        };

        const team = function() {
            gsap.from('.js-teamCarousel', {
                scrollTrigger: {
                    trigger: '.js-teamCarousel',
                    scrub: 2.2,
                    start: 'top bottom',
                    end: 'top center'
                },      
                xPercent: 15
            });
        };

        const autoWidth = function() {
            gsap.utils.toArray(".a-autoWidth").forEach(function(section) {
                gsap.set(section, { 
                    width: 0,
                    opacity: 0
                })
                
                gsap.to(section, {
                    scrollTrigger: {
                        trigger: section,
                        start: '50px bottom',
                        end: 'bottom',
                        toggleActions: "play complete complete complete",
                    },
                    width: 'auto',
                    duration: 1,
                    opacity: 1
                });
            });
        }
        
        const benefits = function() {
            gsap.from('.c-benefits__swiper', {
                scrollTrigger: {
                    trigger: '.c-benefits__swiper',
                    scrub: 2.2,
                    start: 'top bottom',
                    end: 'top center'
                },
                opacity: 0,
                xPercent: -15
            });
        };
        
        const fadeIn = function() {
            gsap.utils.toArray(".a-fadeIn > *").forEach(function(section) {
                gsap.from(section, {
                    scrollTrigger: {
                        trigger: section,
                        start: '-50px bottom',
                        end: 'bottom',
                        toggleActions: "play complete complete reset",
                    },
                    opacity: 0, 
                    duration: 1,
                    y: '30px'
                });
            });
        }
        
        const fadeInOnce = function() {
            gsap.utils.toArray(".a-fadeIn--once > *").forEach(function(section) {
                gsap.from(section, {
                    scrollTrigger: {
                        trigger: section,
                        start: '-50px bottom',
                        end: 'bottom',
                    },
                    opacity: 0, 
                    duration: 1,
                    y: '30px'
                });
            });
        }
        
        const searchForm = function() {
            const tl = gsap.timeline().delay(.25).pause();
            
            tl.
                from('#searchForm .form-row:nth-of-type(1) .form-group:nth-of-type(1)', { 
                    autoAlpha: 0,
                    duration: .5, 
                    x: -10,
                }).
                
                from('#searchForm .form-row:nth-of-type(1) .form-group:nth-of-type(2)', { 
                    autoAlpha: 0,
                    duration: .5, 
                    x: 10,
                }, '-=.5').
                
                from('#searchForm .form-row:nth-of-type(2) .form-group', { 
                    autoAlpha: 0,
                    duration: .5, 
                    scaleX: .95,
                }, '-=.5').
                
                from('#searchForm .form-row:nth-of-type(3) .form-group', { 
                    autoAlpha: 0,
                    duration: .5, 
                    x: 10,
                }, '-=.5').
                
                from('#searchForm .form-row:nth-of-type(4) .form-group', { 
                    autoAlpha: 0,
                    duration: .5, 
                    y: 10,
                }, '-=.5').
                
                from('#searchForm .form-row:nth-of-type(5) .form-group', { 
                    autoAlpha: 0,
                    duration: .5, 
                    x: -10,
                }, '-=.3').
                
                from('#searchForm .form-row:nth-of-type(6) .form-group', { 
                    autoAlpha: 0,
                    duration: .5, 
                    y: -10,
                }, '-=.4')
            
            let section1Timeline = gsap.timeline({
                scrollTrigger: {
                    trigger: "#searchForm",
                    onEnter: () => {
                       tl.play();
                    },
                }
            });
        }
        
        window.startAnimms = function() {
            document.getElementsByClassName('c-areas')[0] ? areas() : false;
            
            if (!document.body.classList.contains('page-it')) {
                document.getElementById('searchForm') ? searchForm() : false;
            }

            document.getElementsByClassName('js-lifeSwiper')[0] ? life() : false;
            document.getElementsByClassName('js-teamCarousel')[0] ? team() : false;
            document.getElementsByClassName('c-benefits__swiper').length > 0 ? benefits() : false;
            document.getElementsByClassName('c-mockups').length > 0 ? mockups() : false;
            document.getElementsByClassName('a-autoWidth').length > 0 ? autoWidth() : false;
            document.getElementsByClassName('a-fadeIn').length > 0 ? fadeIn() : false;
            document.getElementsByClassName('a-fadeIn--once').length > 0 ? fadeInOnce() : false;
            document.getElementsByClassName('c-numbers') ? numbers() : false;            
            
            const stagger = document.querySelectorAll('.js-stagger');
            if (stagger.length > 0) {
                const sections = gsap.utils.toArray(".js-stagger");
                sections.forEach( section => {
                    gsap.from(section.querySelectorAll('.js-item'), {
                        opacity: 0,
                        x: 40,
                        stagger: 0.1,
                        scrollTrigger: {
                            trigger: section,
                            start: "top bottom-=200",
                            toggleActions: "play complete complete reverse",
                        }
                    });
                });
            }
            
/*
            const it_departments = document.querySelector('.c-it_departments');
            if (it_departments) {
                gsap.from(it_departments.querySelector('.swiper'), {
                    scrollTrigger: {
                        trigger: it_departments.querySelector('.swiper .swiper-wrapper'),
                        scrub: 2.2,
                        start: 'top bottom',
                        end: 'top center'
                    },
                    rotate: -5,
                    scale: 1.05
                });
            }
*/
            
            const it_workwithus = document.querySelector('.o-it_workwithus');
            if (it_workwithus) {
                gsap.from(it_workwithus, {
                    scrollTrigger: {
                        trigger: it_workwithus,
                        scrub: 2.2,
                        start: 'top bottom',
                        end: 'top center',
                    },      
                    opacity: .1,
                    yPercent: 50
                });
            }
            
            if (document.querySelector('.c-it_cloud')) {  
                let i = 0, path = document.querySelectorAll('.c-it_cloud .darkmode path');
                
               // ScrollTrigger.matchMedia({
                    //"(min-width: 769px)": () => {
                        
                        document.body.classList.contains('light-mode') ? path = document.querySelectorAll('.c-it_cloud .lightmode path') : false;                  
    
                        ScrollTrigger.batch(".c-it_cloud", {
                            onEnter: elements => {
                                gsap.from(path, {
                                    autoAlpha: 0,
                                    scale: 2,
                                    stagger: {
                                        each: .05,
                                        onComplete: function() {
                                            gsap.to(path[i], {
                                                opacity: .4,
                                                scale: .94,
                                                duration: Math.random(3)+1,
                                                repeat: -1, yoyo: true,
                                                ease: Power1.easeInOut
                                            })
                                            i++; 
                                        }
                                    },
                                    duration: 2,
                                    transformOrigin:"50% 50%",
                                    ease: 'elastic.out(.4, .8)',
                                });
                            },
                            once: 1
                        }); 
    /*
                        ScrollTrigger.addEventListener("refreshInit", () => gsap.set(".c-it_cloud__desktop path", {
                            autoAlpha: 0,
                            scale: 1,
                        }));
    */
                    //},

                   /*
 "(max-width: 768px)": () => {
                        path = document.querySelectorAll('.c-it_cloud__mobile path');
                        
                        ScrollTrigger.batch(".c-it_cloud__mobile", {
                            onEnter: elements => {
                                gsap.from(path, {
                                    autoAlpha: 0,
                                    scale: 2,
                                    stagger: {
                                        each: .1,
                                        onComplete: function() {
                                            gsap.to(path[i], {
                                                opacity: .4,
                                                scale: .94,
                                                duration: Math.random(3)+1,
                                                repeat: -1, yoyo: true,
                                                ease: Power1.easeInOut
                                            })
                                            i++; 
                                        }
                                    },
                                    duration: 2,
                                    transformOrigin:"50% 50%",
                                    ease: 'elastic.out(.4, .8)',
                                });
                            },
                            once: 1
                        });   
                    }
*/
                //})          
            };
            
            window.st = ScrollTrigger;
        }
        
        document.querySelector('.c-hero--home') ? hero() : false;
        document.querySelector('.c-hero--pro') ? startAnimms() : false;
    };

}, false)
