import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

document.addEventListener('DOMContentLoaded',function() {
    gsap.registerPlugin(ScrollTrigger);
    const img = document.querySelector('.js-randomImg');    
    const init = function() {
        const queryString = window.location.search;
        let area, imageslo, imageshi;
        
        if (queryString) {
            const urlParams = new URLSearchParams(queryString);
                  area = urlParams.get('area');
                  
            const simgLo = img.dataset.simglo.split(','),
              simgHi = img.dataset.simghi.split(','),
              cimgLo = img.dataset.cimglo.split(','),
              cimgHi = img.dataset.cimghi.split(','),
              pimgLo = img.dataset.pimglo.split(','),
              pimgHi = img.dataset.pimghi.split(',');
        
            imageslo = img.dataset.dimglo.split(','), 
            imageshi = img.dataset.dimghi.split(',');
            
            switch(area) {
                case 'Centrala': imageslo = cimgLo; imageshi = cimgHi; break;
                case 'Sklep': imageslo = simgLo; imageshi = simgHi; break;
                case 'Staże/praktyki': imageslo = pimgLo; imageshi = pimgHi; break;
                default: break;
            }
        } else {
            if (img.dataset.imglo && img.dataset.imghi) {
                imageslo = img.dataset.imglo.split(','), 
                imageshi = img.dataset.imghi.split(',');
            }
        }

        const randomNum = Math.floor(Math.random() * imageslo.length);

        img.dataset.srcset = imageslo[randomNum].trim() + ' 1x, ' + imageshi[randomNum].trim() + ' 2x';
        img.addEventListener('load', function(e) {                
            img.width = e.target.width;
            img.height = e.target.height;

            gsap.to(".c-banner img:last-of-type", {
                xPercent: -15,
                ease: "none",
                scrollTrigger: {
                    trigger: ".c-banner",
                    scrub: true,
                }, 
            });  
        })
    };

    img ? init() : false;

}, false);
