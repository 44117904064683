import css from './sass/style.scss';

// import * as dotenv from 'dotenv'
// dotenv.config()

require('./js/accordion')
require('./js/anims')
require('./js/carousels')
require('./js/cinema')
require('./js/cinema-carousel')
require('./js/countup')
require('./js/fonts')
require('./js/init')
require('./js/ismobile')
require('./js/it_countup')
require('./js/it_departments')
require('./js/it_follow-mouse')
require('./js/it_positive')
require('./js/it_scrollpos')
require('./js/lazy')
require('./js/nav')
require('./js/page-mode')
require('./js/particles')
require('./js/polyfills')
require('./js/random-img')
require('./js/random-img-fachowcy')
require('./js/show-more')
require('./js/simplebar')
require('./js/smoothscroll')
require('./js/stickkybar')
require('./js/stickybits')
require('./js/erecruter')
require('./js/erecruter-offer')
require('./js/videooffer')
require('./js/videowidget')
