import maplibregl from 'maplibre-gl';
require('../../node_modules/maplibre-gl/dist/maplibre-gl.css');

let map = false;
let markers = [];
const key = 'A2ALq7ki8PITyLtIR7st';

export function getMapKey() {
    return key;
}

export function loadMap(offer, data, lat = null, lng = null, setCenter = false) {
    if (!document.getElementById('map')) {
        return;
    }

    if (offer) {
        let icon = document.createElement('div');
        icon.className = 'marker';

        const map = new maplibregl.Map({
            container: 'map', // container id
            // style: `https://api.maptiler.com/maps/streets-v2-light/style.json?key=${key}`, // style URL
            style: `https://api.maptiler.com/maps/d8fa1eef-77ba-4294-a927-536251522843/style.json?key=${key}`, // style URL
            center: [data.longitude, data.latitude], // starting position [lng, lat]
            zoom: 6, // starting zoom
        });
        map.addControl(new maplibregl.NavigationControl(), 'bottom-right');
        map.scrollZoom.disable();

        new maplibregl.Marker(icon)
          .setLngLat([data.longitude, data.latitude])
          .addTo(map);
    } else {
        let zoom =  lat && lng ? 7 : 5.5;
        lat = lat ? lat : 52.0;
        lng = lng ? lng : 19.5;
        if (!map) {
            map = new maplibregl.Map({
                container: 'map', // container id
                style: `https://api.maptiler.com/maps/d8fa1eef-77ba-4294-a927-536251522843/style.json?key=${key}`, // style URL
                // style: `https://api.maptiler.com/maps/09781b4f-3d4d-4f3a-bd4e-7aa69b1641f9?key=${key}`, // style URL
                center: [lng, lat], // starting position [lng, lat]
                zoom: zoom, // starting zoom
            });
            map.addControl(new maplibregl.NavigationControl(), 'bottom-right');
            map.scrollZoom.disable();
        } else {
            if (setCenter) {
                map.jumpTo({
                    center: [lng, lat],
                    zoom: zoom
                });
            }
        }

        let list = [];
        markers.forEach((item) => {
            item.remove();
        });

        markers = [];

        data.forEach((item) => {
            if (!isNaN((item.latitude)) && !isNaN((item.longitude)) && list.indexOf(item.latitude + item.longitude) === -1) {
                list.push(item.latitude + item.longitude)

                let icon = document.createElement('div');
                icon.className = 'marker';
                icon.dataset.latitude = item.latitude;
                icon.dataset.longitude = item.longitude;
                icon.onclick = function (e) {
                    let elements = document.getElementsByClassName('marker selected');
                    if (elements.length &&
                      elements[0].dataset.latitude === e.target.dataset.latitude &&
                      elements[0].dataset.longitude === e.target.dataset.longitude
                    ) {
                        elements[0].classList.remove('selected');
                        window.offerSearchComponent.setMarker(null, null);
                    } else {
                        if (elements.length) {
                            elements[0].classList.remove('selected');
                        }

                        e.target.classList.add('selected');
                        window.offerSearchComponent.setMarker(item.longitude, item.latitude);
                    }
                    document.querySelector('#results') ? window.runScroll('#results', 0) : false;
                }

                let marker = new maplibregl.Marker(icon)
                  .setLngLat([item.longitude, item.latitude])
                  .addTo(map);

                markers.push(marker)
            }
        })

        map.on('sourcedata', function(e) {
            if (document.body.classList.contains('page-search-filtered')) {
                e.isSourceLoaded ? window.runScroll('#results', 0) : false;
            }
        });

    }
};
