document.addEventListener('DOMContentLoaded', () => {   
    const el = document.querySelector('.o-scrolldown'); 
    const action = function() {
        let scroll_pos = window.pageYOffset || window.scrollY;
        scroll_pos > 0 ? el.classList.add('is-invisible') : 
                         el.classList.remove('is-invisible');
    }

    el ? action() : false;
    el ? window.addEventListener('scroll', action) : false;

}, false);
