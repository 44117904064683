import SimpleBar from 'simplebar';
import 'simplebar/dist/simplebar.css';

document.addEventListener('DOMContentLoaded',function() {

    const el = document.getElementsByClassName('js-simplebar');
    
    const init = function() {
        for (let i = 0; i < el.length; i++) {
            new SimpleBar(el[i], {
                autoHide: false
            });
        }
    };
    
    el.length > 0 ? init() : false;    

}, false)
