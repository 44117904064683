import { gsap, Elastic, Power1, TweenMax, Power3 } from "gsap";

document.addEventListener('DOMContentLoaded',function() {

    const el = document.querySelector('.js-positive');

    const init = (obj, mobile_only) => {
        let item = el.querySelectorAll('.js-item'),
            mediaQuery = '(min-width: 1025px)',
            mediaQueryList = window.matchMedia(mediaQuery),
            tl, stopit = true;
  
        const toggle = function(ev, section, op) {
            tl = gsap.timeline({clearProps:"all"})
            .to(section, {
                height: ev,
                duration: .25,
                ease: Power1.easeOut,
                opacity: op
            })
        }

        const showIt = function(event) {
            let _that = event.currentTarget;
            if (event.propertyName ==='width') {
                if (!stopit) {
                    toggle('auto', _that.getElementsByClassName('js-section')[0], 1);
                }
            }
        }
        
        const action = (e) => {
            const _that = e.currentTarget,
                  _height = 0;
            
            if (_that.classList.contains('is-rollout')) {
                stopit = true;
                _that.classList.remove('is-rollout');
                _that.removeEventListener("transitionend", showIt);
                toggle(_height, _that.getElementsByClassName('js-section')[0], 0);                   
            } else {
                for (let i = 0; i < item.length; i ++) {
                    item[i].classList.remove('is-rollout');
                    item[i].removeEventListener("transitionend", showIt);
                    toggle(_height, item[i].getElementsByClassName('js-section')[0], 0);
                }

                stopit = false;
                _that.classList.add('is-rollout');
                _that.addEventListener("transitionend", showIt);
            }
        };

        const start_desktop = () => {                
            for( let i = 0; i < item.length; i ++ ) {    
                item[i].addEventListener('mouseenter', action);
                item[i].addEventListener('mouseout', action);
            }        
        }
        
        const destroy = () => {
            tl ? tl.kill(1,1) : false;

            for( let i = 0; i < item.length; i ++ ) {    
                item[i].removeEventListener('mouseenter', action);
                item[i].removeEventListener('mouseout', action);
                item[i].querySelector('.js-section').removeAttribute('style')
            }
        }
        
        mediaQueryList.addEventListener('change', event => {
            event.matches ? start_desktop() : destroy();
        })

        mediaQueryList.matches ? start_desktop() : false;        
    };

    el ? init() : false;
    
    //el_it ? init_it(el_it, 1) : false;

}, false);