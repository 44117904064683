document.addEventListener('DOMContentLoaded', ()=> {
    const el = document.querySelectorAll('.js-img-fachowcy');

    const init = function() {
        const getRandomArbitrary = (min, max)=> {
            return Math.floor(Math.random() * (max - min) + min);
        }

        let img = document.querySelector('.img-fachowcy--'+getRandomArbitrary(1, 4));
        img.style.display = 'block';
    }

    window.fachowcyImg = init;

    el.length > 0 ? init() : false;

}, false);
