import { CountUp } from 'countup.js';
import InView from 'inview';

document.addEventListener('DOMContentLoaded', () => {    
    
    let count = document.querySelectorAll('[data-count]');

    const countUpInit = () => {
        count = document.querySelectorAll('[data-count]');

        const options = {
            duration: 3,
        };
        
        const option_nocomma = {
            separator: ''
        }
        
        for (let i = 0; i < count.length; i++) {
            const inview = InView(count[i], function(isInView) {
                if (isInView) {
    
                    if (i === 0) {
                        let demo = new CountUp(count[i], count[i].getAttribute('data-count'), options);
                	    !demo.error ? demo.start() : false;
                    } else {   
                        if (count[i].classList.contains('no-comma')) {
                            let demo = new CountUp(count[i], count[i].getAttribute('data-count'), option_nocomma);
                    	    !demo.error ? demo.start() : false;
                        } else {
                            let demo = new CountUp(count[i], count[i].getAttribute('data-count'), options);
                    	    !demo.error ? demo.start() : false;
                        }
                    }
                    this.destroy();
                }
            });
        }
    };
    
    window.countUpInit = countUpInit;

    count.length > 0 ? countUpInit() : false;

}, false);