import Cookies from 'js-cookie';

document.addEventListener('DOMContentLoaded',function() {

    const cover = document.getElementById('cover');
    
    if (document.querySelector('.js-videowidget')) {
    Cookies.get('leroy-merlin-kariera-videowidget') === 'close' ? document.querySelector('.js-videowidget').classList.add('avatar-only') : false;
    }

    const init = function() {
        document.documentElement.removeAttribute('style');
        document.documentElement.classList.add('is-loaded');        
        window.animsInit();
        window.carousels();

        setTimeout(function() {
            
            //if ( (document.querySelector('.js-videowidget')) && (document.documentElement.classList.contains('is-desktop')) ) {
            if (document.querySelector('.js-videowidget')) {
                let widgetOpened = false, widgetLoaded = false;
                
                document.querySelector('.js-videowidget').querySelector('.js-close').addEventListener('click', () => { 
                    document.querySelector('.js-videowidget').classList.add('avatar-only');
                    Cookies.set("leroy-merlin-kariera-videowidget", 'close', { expires: 356, path: '/' });
                });

                document.querySelector('.js-videowidget').querySelector('.js-avatar').addEventListener('click', () => {
                    if (widgetLoaded === false) {
                        widgetLoaded = true;
                        window.videoWidget.init();
                        
                        document.querySelector('.js-videowidget').classList.add('is-visible');
                        document.querySelector('.js-videowidget').classList.remove('avatar-only');
        	            window.videoWidget.toggle('auto', document.querySelector('.js-videowidget').querySelector('.js-content'), 1);
        	            widget = !widget;
        	            widgetOpened = !widgetOpened;
        	            
/*
                        const action = function() {
                            
                            if( (Cookies.get('leroy-merlin-kariera-videowidget') != 'hide') && (Cookies.get('leroy-merlin-kariera-videowidget') != 'close') ) {
                                if (!document.querySelector('.js-videowidget').classList.contains('avatar-only')) {
                                    let scroll_pos = window.pageYOffset || window.scrollY, widget = false;
                                    if ((scroll_pos > 100) && (widget === false) && (widgetOpened === false)) {
                                        document.querySelector('.js-videowidget').classList.add('is-visible');
                        	            window.videoWidget.toggle('auto', document.querySelector('.js-videowidget').querySelector('.js-content'), 1);
                        	            widget = !widget;
                        	            widgetOpened = !widgetOpened;
                                    }
                                }
                            }
                        }
                    
                        window.addEventListener('scroll', action);
*/
                    }
                })
                

                const action = function() {
                            
                    if( (Cookies.get('leroy-merlin-kariera-videowidget') != 'hide') && (Cookies.get('leroy-merlin-kariera-videowidget') != 'close') ) {
                        if (!document.querySelector('.js-videowidget').classList.contains('avatar-only')) {
                            let scroll_pos = window.pageYOffset || window.scrollY, widget = false;
                            if ((scroll_pos > 100) && (widget === false) && (widgetOpened === false)) {
                                
                                if (widgetLoaded === false) {
                                    widgetLoaded = true;
                                    window.videoWidget.init();
                                        
                                    document.querySelector('.js-videowidget').classList.add('is-visible');
                    	            window.videoWidget.toggle('auto', document.querySelector('.js-videowidget').querySelector('.js-content'), 1);
                    	            widget = !widget;
                    	            widgetOpened = !widgetOpened;
                    	       }
                            }
                        }
                    }
                }
            
                window.addEventListener('scroll', action);
            }
            
            !document.querySelector('.c-hero') ? window.startAnimms() : false;
            
            window.location.hash ? window.runScroll( '#'+document.getElementById(window.location.hash.substring(1)).id ) : false;
            history.pushState("", document.title, window.location.pathname + window.location.search);
            cover.remove();
            document.querySelector('#it_hero_video') ? document.querySelector('#it_hero_video').play() : false;
        }, 250);  
    };
    
    window.addEventListener('load', init);

}, false);
