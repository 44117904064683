import Swiper, {Navigation, Pagination} from "swiper";

export default () => {
    const it_featuredoffers = document.querySelector('.js-it_featuredoffers');
    if (it_featuredoffers) {
        const container = document.querySelector('.c-it_featuredoffers');

        const swiper = new Swiper(it_featuredoffers, {
            centeredSlides: true,
            modules: [Navigation, Pagination],
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            breakpoints: {
                769: {
                    slidesPerView: 3
                }
            },
            on: {

                slideChangeTransitionStart: function (obj) {
                    document.querySelector('.c-it_light').classList.add('light-off');

                    // container.classList.remove('light');

                    /*
                                           document.querySelectorAll('.swiper-slide').forEach(function (slide) {
                                                slide.classList.remove('light-on');
                                                obj.slides[obj.realIndex].classList.add('light-on')
                                            });
                    */
                },

                slideChangeTransitionEnd: function (obj) {
                    document.querySelector('.c-it_light').classList.remove('light-off');
                },
                //slideChangeTransitionEnd: function (obj) {

                // container.classList.add('light');

                /*
document.querySelectorAll('.swiper-slide').forEach(function (slide) {
                    slide.classList.remove('light-on');
                    obj.slides[obj.realIndex].classList.add('light-on')
                });
*/
                //},
                /*
                                    init: function (obj) {
                                        obj.slides[1].classList.add('light-on')
                                    },
                */
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            slidesPerView: 'auto',
            preventClicks: true,
            preventClicksPropagation: true,
            preventInteractionOnTransition: true,
            slideToClickedSlide: true,
            speed: 500,
        });

        swiper.slideTo(1, 0, document.querySelector('.c-it_light').classList.remove('light-off'));
    }

}
