import Swiper, { Pagination } from 'swiper';
import 'swiper/swiper.scss';
import { CountUp } from 'countup.js';

document.addEventListener('DOMContentLoaded', () => {    
    
    const elements = document.querySelectorAll('[data-it_count]');
    
    const it_countUpInit = () => {
        const elements = document.querySelectorAll('[data-it_count]');

        function animate(element, finalValue) {
            return new Promise(function(resolve, reject) {    
                let options = {
                    duration: 3,
                    onCompleteCallback: function() {
                        //console.log("Zakończono animację dla elementu z klasą:", element.className);
                        element.parentNode.classList.add('finish');
                        resolve();
                    },
                    enableScrollSpy: true,
                    scrollSpyOnce: true
                };
            
                let countUp = new CountUp(element, finalValue, options);
                if (!countUp.error) {
                    countUp.start();
                } else {
                    reject(countUp.error);
                }
            });
        }
        
        let promises = [];
        elements.forEach(function(element) {
            let finalValue = parseFloat(element.getAttribute('data-it_count'));
            promises.push(animate(element, finalValue));
        });
    
        Promise.all(promises)
          .then(function() {
    
          })
          .catch(function(error) {
            console.error("Wystąpił błąd podczas animacji:", error);
          });
    };
    
    window.it_countUpInit = it_countUpInit;    
    
    elements.length > 0 ? it_countUpInit() : false;

}, false);