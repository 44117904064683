import { gsap, TweenMax, Power3 } from "gsap";
import Plyr from 'plyr';
require('../../node_modules/plyr/dist/plyr.css');
document.addEventListener('DOMContentLoaded', function() {

    window.cinemaEl = document.querySelector('#cinema');

    let _this,
        player,
        player_visible = false,
        status,
        status_click = false,
        categoriesLoaded = false;

    window.cinema = {
        open: (e, click) => {
            event.preventDefault();
            _this = window.cinema;
            status_click = click;

            if (window.widgetPlayer != undefined) {
                window.widgetPlayer.pause();
            }

            if (!document.querySelector('#cinema').classList.contains('is-visible')) {
                gsap.set(".js-filters", {x: -20, opacity: 0});
                setTimeout(function() {
                    document.querySelector('#cinema').classList.add('is-ready');
                    _this.getJSON(null, click);
                    _this.changeFilters(click);
                    document.querySelector('#cinema').classList.add('is-visible');
                }, 250);
            }
            _this.action(e);
        },

        action: (e) => {
            setTimeout(function() {
                e.dataset.id ? _this.player_show(e) : false;
            }, 250);

            cinemaEl.querySelector('.js-closeapla').addEventListener('click', () => _this.close(), { once: true })

        },

        changeFilters: () => {

            _this.changeFilters = function() {};

            let filters = cinemaEl.querySelector('.js-navFilters'),
                closeFilter = cinemaEl.querySelectorAll('.js-closefilters');

            cinemaEl.querySelector('.js-changeFilters').addEventListener('click', (e) => {
                e.preventDefault();

                if (!filters.classList.contains('is-visible')) {
                    _this.player_close();
                    filters.classList.add('is-visible');
                    document.querySelector('.js-closefilters').style.display = 'block';
                    e.currentTarget.querySelector('.js-name').innerHTML = e.currentTarget.dataset.filtered;
                    setTimeout(function() {
                        gsap.to(filters, .5, {
                            opacity: 1,
                            immediateRender: false,
                            ease: Power3.easeOut
                        })
                    }, 10);
                } else {
                    document.querySelector('.js-closefilters').removeAttribute('style');
                    e.currentTarget.querySelector('.js-name').innerHTML = e.currentTarget.dataset.filter;
                    setTimeout(function() {
                        gsap.to(filters, .5, {
                            opacity: 0,
                            immediateRender: false,
                            ease: Power3.easeOut
                        })
                    }, 10);

                    setTimeout(function() {
                        filters.classList.remove('is-visible');
                    }, 500)
                }
            });

            for (let i = 0; i < closeFilter.length; i++) {
                closeFilter[i].addEventListener('click', (e) => {
                    e.preventDefault();
                    document.querySelector('.js-closefilters').removeAttribute('style');
                    gsap.to(filters, .5, {
                        opacity: 0,
                        immediateRender: false,
                        ease: Power3.easeOut
                    })
                    setTimeout(function() {
                        filters.classList.remove('is-visible');
                        filters.removeAttribute('style');
                    }, 500);
                });
            }
        },

        close: () => {
            player_visible = false;
            document.querySelector('#cinema').classList.remove('is-ready', 'is-visible');
            player != undefined ? player.stop() : false;
            _this.player_toggle(0, cinemaEl.querySelector('.js-vid'), 0);
            cinemaEl.querySelectorAll("input[type=checkbox]").forEach((checkbox) => {
                checkbox.checked = false;
            })
            cinemaEl.querySelector('.js-navFilters').classList.remove('is-visible')
            cinemaEl.querySelector('.js-navFilters').removeAttribute('style');
            cinemaEl.querySelector('.js-activeFilters').removeAttribute('style');
            cinemaEl.querySelector('.js-items').innerHTML = '';
        },

        show_data: (data) => {
            let html = '';

/*
            const action = function(err, data) {
                if (err === null) {
                    console.clear();
                    console.log(data.thumbnail_url);
                } else {
                    console.log(err);
                }
            }
*/

            //_this.getJSONx('https://vimeo.com/api/oembed.json?url=https://vimeo.com/773815313&width=640', action);

            data.forEach(function(item) {
                html += '<li>';
                html += '<figure class="o-box" onclick="cinema.open(this)" data-id="' +item.id+'">';
                //html += '<img data-srcset="'+item.thumbnail_lo+' 1x, '+item.thumbnail_hi+' 2x" data-src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" alt="" class="lazy" width="294" height="400" />';

                html += '<img data-src="'+item.thumbnail_hi+'" alt="" class="lazy" width="294" height="400" />';
                html += '<figcaption>'
                html += '<p><strong>' + item.name + ',</strong> '+ item.position + '</p>'
                html += '</figcaption>'
                html += '</figure>'
                html += '</li>'
            });
            cinemaEl.querySelector('.js-items').innerHTML = html;
            window.myLazyLoad.update();
        },

        show_categories: (data, click) => {
            // console.clear();
            // console.log(data);

            let html = '';
            let areas = [], categories = [], areas_join = [], categories_join = [], unique;

            function capitalizeFirstLetter(string) {
                return string.charAt(0).toUpperCase() + string.slice(1);
            }

            for(var i in data) {
                areas.push(data[i].areas);
                categories.push(data[i].category_name);
            }

            for (let i = 0; i < categories.length; i++) {
                if (categories[i].length > 0) {
                    for(var x in categories[i]) {
                        categories_join.push(categories[i][x]);
                    }
                }
            }

            unique = Array.from(new Set(categories_join));
            unique.forEach(function(item) {

                let itemSlug = item;
                itemSlug = itemSlug.replace("ó","o");
                itemSlug = itemSlug.replace("ą","a");
                itemSlug = itemSlug.replace("Ó","o");
                itemSlug = itemSlug.replace("ł","l");
                itemSlug = itemSlug.replace("Ł","l");
                itemSlug = itemSlug.replace("ń","n");
                itemSlug = itemSlug.replace("Ń","n");
                itemSlug = itemSlug.replace("ż","z");
                itemSlug = itemSlug.replace("Ż","z");
                itemSlug = itemSlug.replace("ź","z");
                itemSlug = itemSlug.replace("Ź","z");
                itemSlug = itemSlug.replace("Ć","c");
                itemSlug = itemSlug.replace("ć","c");
                itemSlug = itemSlug.replace("ę","e");
                itemSlug = itemSlug.replace("Ę","e");
                itemSlug = itemSlug.replace("Ś","s");
                itemSlug = itemSlug.replace("ś","s");
                itemSlug = itemSlug.replace("ą","a");
                itemSlug = itemSlug.replace(",","");
                itemSlug = itemSlug.replace(/\s/g, '-');

                html += '<li>';
                html += '<input type="checkbox" id="'+item+'" name="categories" value="'+itemSlug.toLowerCase()+'">';
                html += '<label for="'+item+'">';
                html += '<span>'+item+'</span>';
                html += '</label>';
                html += '</li>';
            });
            cinemaEl.querySelector('.js-filters_categories').innerHTML = html;

            for (let i = 0; i < areas.length; i++) {
                if (areas[i].length > 0) {
                    for(var x in areas[i]) {
                        areas_join.push(areas[i][x]);
                    }
                }
            }

            html = '';
            unique = Array.from(new Set(areas_join));
            unique.forEach(function(item) {
                html += '<li>';
                html += '<input type="checkbox" id="'+item+'" name="areas" value="'+item+'">';
                html += '<label for="'+item+'">';
                html += '<span>'+capitalizeFirstLetter(item.replace(/-/g, ' '))+'</span>';
                html += '</label>';
                html += '</li>';
            });
            cinemaEl.querySelector('.js-filters_areas').innerHTML = html;


            _this.filters(click);
            categoriesLoaded = true;
        },

        filters: (click) => {
            _this.filters = function() {};

            cinemaEl.querySelectorAll("input[type=checkbox]").forEach(function(checkbox) {
                checkbox.addEventListener('change', () => {
                    cinemaEl.querySelector('.js-items').innerHTML = '';

                    player != undefined ? player.stop() : false;

                    if (status_click === false) {
                        _this.player_toggle(0, cinemaEl.querySelector('.js-vid'), 0);
                    } else {
                        status_click = false;
                    }
                    _this.filters_submit();
                });
            })

            gsap.to(cinemaEl.querySelector('.js-filters'), .5, {
                x: 0,
                opacity: 1,
                immediateRender: false,
                ease: Power3.easeOut
            })
        },

        filters_submit: () => {
            let input = cinemaEl.getElementsByTagName('input'),
                results_category = new Array(),
                results_areas = new Array(), results = '';

            for (let i = 0; i < input.length; i++) {
                if (input[i].checked === true) {
                    input[i].name === 'categories' ? results_category.push(input[i].value) : results_areas.push(input[i].value);
                }
            }

            if ((results_category.length > 0) && (results_areas.length > 0)) {
                results = '"categories"' + ':' + JSON.stringify(results_category);
                results += ',"areas"' + ':' + JSON.stringify(results_areas);
            } else {
                results_category.length > 0 ? results = '"categories"' + ':' + JSON.stringify(results_category) : false;
                results_areas.length > 0 ? results += '"areas"' + ':' + JSON.stringify(results_areas) : false;
            }

            _this.getJSON( JSON.parse("{"+results+"}"));
            cinemaEl.querySelector('.js-activeFilters').innerHTML =
                cinemaEl.querySelectorAll("input[type=checkbox]:checked").length;

            cinemaEl.querySelectorAll("input[type=checkbox]:checked").length > 0 ?
                cinemaEl.querySelector('.js-activeFilters').style.display = 'block' :
                cinemaEl.querySelector('.js-activeFilters').removeAttribute('style');
        },

        getJSON: (payload, click) => {
            cinemaEl.querySelector('.js-contentContainer').classList.add('is-loading');
            (async () => {
                const rawResponse = await fetch(process.env.API_MOVIES_URL, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(payload)
                });
                const content = await rawResponse.json();
                cinemaEl.querySelector('.js-contentContainer').classList.remove('is-loading');
                _this.show_data(content);

                !categoriesLoaded ? _this.show_categories(content, click) : gsap.to(cinemaEl.querySelector('.js-filters'), .5, { x: 0, opacity: 1, immediateRender: false, ease: Power3.easeOut });

                // Set active category
                if (status_click === true) {
                    //setTimeout(function() {
                        cinemaEl.querySelectorAll("input[type=checkbox]").forEach((checkbox) => {
                            if (checkbox.value === window.cinemaCategory) {
                                checkbox.checked = true;
                                let event = new Event('change');
                                checkbox.dispatchEvent(event);
                            }
                        })
                    //}, 3000);

                    status_click = false;
                }

            })();
        },

        player_toggle: (ev, section, op) => {
            TweenMax.to(section, .5, {
                height: ev,
                opacity: op,
                immediateRender: false,
                ease: Power3.easeOut
            });
        },

        player_create: () => {
            player = new Plyr('#screen', {
                autoplay: true,
                vimeo: {
                    sidedock: 0,
                    controls: 0,
                },
                controls: ['play', 'progress', 'volume', 'mute']
            });

            player.on('ready', (event) => {
                player.stop();
                player_visible = true;
                _this.player_toggle('auto', cinemaEl.querySelector('.js-vid'), 1);
            });

            player.on('playing', (event) => {
                setTimeout(function() {
                    player_visible  ? player.play() : player.stop();
                }, 10)
            });

            cinemaEl.querySelector('.js-vidHide').addEventListener('click', _this.player_close);
        },

        player_close: () => {
            player_visible = false;
            _this.player_toggle(0, cinemaEl.querySelector('.js-vid'), 0);
            player != undefined ? player.stop() : false;
            cinemaEl.classList.remove('video-open');

            gsap.to(cinemaEl.querySelector('.js-vid'), .5, {
                height: 0,
                opacity: 0,
                immediateRender: false,
                ease: Power3.easeOut
            })
        },

        player_show: (e) => {
            player === undefined ? _this.player_create() : false;
            cinemaEl.classList.add('video-open');

            (async () => {
                const rawResponse = await fetch(process.env.API_MOVIES_URL, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({"id": e.dataset.id })
                });
                window.content = await rawResponse.json();

                player.source = {
                    type: 'video',
                    sources: [
                        {
                            src: 'https://vimeo.com/' + content[0].vimeoID,
                            provider: 'vimeo',
                        },
                    ],
                };

                window.content[0].name ? cinemaEl.querySelector('.js-vid').querySelector('.js-name').innerHTML = window.content[0].name : false;
                window.content[0].position ? cinemaEl.querySelector('.js-vid').querySelector('.js-pos').innerHTML = window.content[0].position : false;

                let html = '';
                window.content[0].category_name.forEach(function(item) {
                    html += '<p>'+item.replace(/-/g, ' ');+'</p>';
                });
                window.content[0].areas.forEach(function(item) {
                    html += '<p>'+item.replace(/-/g, ' ');+'</p>';
                });
                cinemaEl.querySelector('.js-vidCategories').innerHTML = html;
            })();
        }
    }
}, false);
