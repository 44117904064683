import { gsap, TweenLite, Power1 } from "gsap";

document.addEventListener('DOMContentLoaded',function() {

    window.showMore = (e) => {
        event.preventDefault();
        
        const item = e.parentNode.parentNode.getElementsByClassName('js-item');

        if (!window.showMoreStatus)  {
            e.dataset.def = e.innerHTML;
            window.showMoreItems = new Array();
            for (let i = 0; i < item.length; i++) {
                if (item[i].clientHeight === 0) {
                    window.showMoreItems.push(item[i]);
                }
            }
            window.showMoreStatus = true;
        }

        if (e.classList.contains('is-open')) {            
            for (let j = 0; j < window.showMoreItems.length; j++) {
                let tl = gsap.timeline();
                
                tl.to(window.showMoreItems[j], 0.4, {
                    height: 0,
                    opacity: 0
                }).set(window.showMoreItems[j], { 
                    className:"-=is-visible",
                });
            }
            e.innerHTML = e.dataset.def;
            e.classList.remove('is-open');

        } else {
            for (let j = 0; j < window.showMoreItems.length; j++) {
                TweenLite.set(window.showMoreItems[j], { 
                    className:"is-visible",
                });
                
                TweenLite.set(window.showMoreItems[j], { 
                    height: "auto",
                    opacity: 1
                })
                
                TweenLite.from(window.showMoreItems[j], 0.4, {
                    height: 0,
                    opacity: 0,
                    scaleX: .9,
                }).delay(j/6);
            }
            e.innerHTML = e.dataset.less;
            e.classList.add('is-open');
        }
    };

}, false);

// http://0.0.0.0:8080/oferta.html?id=274212-519-3832609#