document.addEventListener('DOMContentLoaded', () => {  
    
    const follow = ()=> {
        const el = document.querySelector('.js-follow'),
              drag = document.querySelector('.js-drag'),
              titlearea = drag.parentNode.querySelector('.js-drag-titlearea');

        el.addEventListener('mouseenter', (e) => {
            drag.classList.add('is-visible')
        });
        
        el.addEventListener('drag', (e) => {
            drag.classList.remove('is-visible')
        });
        
        el.addEventListener('mouseleave', (e) => {
            drag.classList.remove('is-visible')
        });

        el.addEventListener('mousemove', (e) => {
            const x = e.clientX - 44,
                  y = e.offsetY + titlearea.clientHeight - 44;
            
            drag.style.transform = `translate(${x}px, ${y}px)`;
        })
    }
    
    window.follow = follow;
    document.querySelector('.js-follow') ? follow() : false;

}, false)
