import stickybits from 'stickybits';

document.addEventListener('DOMContentLoaded', () => {
    window.addEventListener('load', () => {
        const el = document.querySelectorAll('.js-sticky'),
              mediaQuery = '(max-width: 1024px)',
              mediaQueryList = window.matchMedia(mediaQuery),
              mediaQueryMobile = '(max-width: 768px)',
              mediaQueryMobileList = window.matchMedia(mediaQueryMobile);
        
        const init = () => {
            if (!mediaQueryMobileList.matches) {
                for (let i = 0; i < el.length; i ++) {
                    let offset;
        
                    if (el[i].dataset.offset) {
                        offset = Number(el[i].dataset.offset);
                        mediaQueryList.matches ? offset += document.querySelector('.js-topbar').clientHeight : false;
                    }
        
                    let stickybit = stickybits(el[i], {
        	            stickyBitStickyOffset: offset
                    });
                }
            }
        };
      
        el.length > 0 ? init() : false;
    
        mediaQueryList.addEventListener('change', event => {
            el.length > 0 ? init() : false;            
        })
        
        mediaQueryMobileList.addEventListener('change', event => {
            if (mediaQueryMobileList.matches) {
                for (let i = 0; i < el.length; i ++) {
                    el[i].removeAttribute('style')
                }
            } else {
                init();
            }
        })
    })
}, false);
