export default  [
        'Platformy digital',
        'Architektura i Software Development',
        'Project Management Office',
        'Bezpieczeństwo IT',
        'Dane i integracja',
        'Infrastruktura',
        'Procesy i Automatyzacja',
        'Sieci i Bezpieczeństwo Sieciowe',
        'Wsparcie IT'
    ];
