import particlesJS from 'particles.js';

document.addEventListener('DOMContentLoaded', () => {
    const init = () => {
        const particlesJS = window.particlesJS;
        particlesJS.load('particles-js', 'json/particles.json', null);
    }

    document.querySelector('#particles-js') ? init() : false;

}, false);