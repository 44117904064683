import Swiper, { FreeMode, Mousewheel, Navigation, Pagination } from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/modules/mousewheel/mousewheel.scss';
import { gsap, Power1 } from "gsap";

document.addEventListener('DOMContentLoaded', () => {
    const el = document.querySelector('#it_departments'),
        mediaQuery = '(max-width: 768px)',
        mediaQueryList = window.matchMedia(mediaQuery);

    const init = (dep_id)=> {

        const departments = el.querySelectorAll('.js-item'),
            description = document.querySelector('#it_departments__description'),
            departments_list = document.querySelector('.c-it_departments__list'),
            faq = document.querySelector('#it_faq'),
            platforms = document.querySelector('#it_platforms');

        let data;
        let dep__id = dep_id;
        let faq_list, platforms_list, loaded = false;

        faq ? faq_list = faq.querySelector('.js-it_accordion') : false;
        platforms ? platforms_list = platforms.querySelector('.js-it_accordion') : false;

        const helpers = {
            convertImages: (query, callback) => {
                const images = document.querySelectorAll(query);

                images.forEach(image => {
                    fetch(image.src)
                        .then(res => res.text())
                        .then(data => {
                            const parser = new DOMParser();
                            const svg = parser.parseFromString(data, 'image/svg+xml').querySelector('svg');

                            if (image.id) svg.id = image.id;
                            if (image.className) svg.classList = image.classList;

                            image.parentNode.replaceChild(svg, image);
                        })
                        .then(callback)
                        .catch(error => console.error(error))
                });
            },
            getJSON: (url)=> {
                let xhr = new XMLHttpRequest();
                xhr.open('GET', url, true);
                //xhr.responseType = 'json';
                xhr.onload = ()=> {
                    if (xhr.status === 200) {
                        let json = xhr.response.replace(/(\r\n|\n|\r)/gm, "");
                        data = JSON.parse(json);
                        departments.forEach((item, i) => {
                            item.addEventListener('click', info.action)
                        })

                        if (dep_id) {
                            info.action(dep_id);



                            // alternative name for block architektura
                            let title_array = platforms.querySelector('h2 span').dataset.names.split(',');
                            if (dep_id === 2) {
                                platforms.querySelector('h2 span').innerHTML = title_array[0];
                            } else {
                                platforms.querySelector('h2 span').innerHTML = title_array[1];
                            }
                        }

                        mediaQueryList.addEventListener('change', event => {
                            if(event.matches) {
                                info.dropdown()
                            } else {
                                departments_list.removeAttribute('style')
                            }
                        })

                        mediaQueryList.matches ? info.dropdown() : false;

                    } else {
                        console.log('error: ' + xhr.status);
                    }
                };
                xhr.send();
            },
            toggle: (ev, section, op, remove) => {
                gsap.fromTo(section,
                    { opacity: 0 },
                    { opacity: 1, duration: .5 });
            }
        }

        const info  = {
            action: (e) => {
                let _this;
                e.currentTarget ? _this = e.currentTarget : _this = document.querySelector('[data-id="'+e+'"]');
                _this.classList.contains('is-active') ? false : info.show(_this)
                loaded = true;
                info.dropdown();
            },

            parse: (item) => {
                let html = '',
                    div = document.createElement("div"),
                    url = new URL(location);

                document.documentElement.dataset.department = 'id-'+item.id;
//                window.history.pushState('', '', url.pathname);

                // Description
                html += '<h3>'+item.title+'</h3>'
                html += '<article>'+item.article+'</article>'

                // Reviews
                if (item.reviews) {
                    html += '<div class="c-it_review">'
                    html +=     '<div class="swiper js-it_department_reviews">'
                    html +=         '<div class="swiper-wrapper">'
                    for (let i = 0; i < item.reviews.length; i++) {
                        html +=         '<div class="swiper-slide">'
                        html +=             '<div class="o-cols">'
                        html +=                 '<div class="o-cols__item">';

                        if (item.reviews[i].img_x1) {

                            html +=                     '<picture class="c-it_review__photo">'
                            html +=                         '<source media="(min-width: 641px)" srcset="'+item.reviews[i].img_x1+', '+item.reviews[i].img_x2+' 2x">'
                            html +=                             '<img src="'+item.reviews[i].img_mobile_x1+'" srcset="'+item.reviews[i].img_mobile_x1+', '+item.reviews[i].img_mobile_x2+' 2x" alt="" loading="lazy" width="200" height="280">'
                            html +=                     '</picture>'

                        }

                        html +=                 '</div>'
                        html +=                 '<div class="o-cols__item">'
                        html +=                     '<blockquote>'
                        html +=                         '<cite>'+item.reviews[i].review+'</cite>'
                        html +=                     '</blockquote>'
                        html +=                 '</div>'
                        html +=             '</div>'
                        html +=         '</div>'
                    }
                    html +=         '</div>'
                    html +=         '<div class="o-pagination">'
                    html +=             '<div class="swiper-button-prev it"></div>'
                    html +=             '<div class="swiper-pagination it"></div>'
                    html +=             '<div class="swiper-button-next it"></div>'
                    html +=         '</div>'
                    html +=     '</div>'
                    html += '</div>'
                }

                // Countup
                if (item.countup === "true") {
                    html += '<div class="c-it_countup">'
                    html +=        '<div class="swiper js-it_countup">'
                    html +=            '<div class="swiper-wrapper">'
                    html +=                '<div class="swiper-slide">'
                    html +=                    '<article>'
                    html +=                        '<p><span data-it_count="4000"></span></p>'
                    html +=                        '<p>zamówień</p>'
                    html +=                        '<p>klientów przetwarzanych dziennie</p>'
                    html +=                    '</article>'
                    html +=                '</div>'
                    html +=                '<div class="swiper-slide">'
                    html +=                    '<article>'
                    html +=                        '<p><span data-it_count="2"></span> mln</p>'
                    html +=                        '<p>linii kodu</p>'
                    html +=                        '<p>napisanego przez Ekipę&nbsp;IT</p>'
                    html +=                    '</article>'
                    html +=                '</div>'
                    html +=                '<div class="swiper-slide">'
                    html +=                    '<article>'
                    html +=                        '<p><span data-it_count="100"></span></p>'
                    html +=                        '<p>wdrożonych</p>'
                    html +=                        '<p>aplikacji i&nbsp;modułów programistycznych</p>'
                    html +=                    '</article>'
                    html +=                '</div>'
                    html +=                '<div class="swiper-slide">'
                    html +=                    '<article>'
                    html +=                        '<p><span data-it_count="78"></span></p>'
                    html +=                        '<p>sklepów</p>'
                    html +=                        '<p>wspieranych przez Ekipę&nbsp;IT</p>'
                    html +=                    '</article>'
                    html +=                '</div>'
                    html +=            '</div>'
                    html +=            '<div class="swiper-pagination it"></div>'
                    html +=    '</div>'
                    //html +=    '<div class="o-dora">'
                    //html +=        '<img src=<%=require("./img/it_countup/dora.svg")%> alt="" loading="lazy" width="1201" height="2092" />'
                    //html +=    '</div>'
                    html +='</div>'
                }

                div.id = "it_department"
                div.className = "o-department"
                div.innerHTML = html
                description.append(div);
                helpers.convertImages('.it_department_cloud')
                item.reviews ? info.reviews_carousel() : false;
                item.countup ? window.it_countUpInit() : false;
                //item.countup ? window.countup_carousel() : false;

                // Faq
                if (item.faq) {
                    let platform = '';

                    faq.style.display = 'block'
                    html = ''
                    for (let i = 0; i < item.faq.length; i++) {

                        if (item.faq[i].platform) {
                            platform = item.faq[i].platform;
                        }

                        html += '<li class="js-item platform-'+platform+'">'
                        html +=     '<p>'+item.faq[i].question+'</p>'
                        html +=     '<div class="js-section">'
                        html +=         '<section>'+item.faq[i].answer+'</section>'
                        html +=     '</div>'
                        html += '</li>'
                    }

                    faq_list.innerHTML = html
                    accordion_it(faq_list);

                    if (document.documentElement.dataset.department === 'id-2') {
                        faq.style.display = 'none';
                    }

                } else {
                    faq.style.display = 'none'
                }

                // Platforms
                if (item.platforms) {
                    // alternative name for Platforms
                    let title_array = platforms.querySelector('h2 span').dataset.names.split(',');
                    dep__id = item.id;

                    if (item.id === "2") {
                        platforms.querySelector('h2 span').innerHTML = title_array[0];
                    } else {
                        platforms.querySelector('h2 span').innerHTML = title_array[1];
                    }

                    platforms.style.display = 'block'
                    html = ''
                    html += '<div class="swiper js-it_platforms js-follow">'
                    html +=     '<ul class="swiper-wrapper">'
                    for (let i = 0; i < item.platforms.length; i++) {
                        html +=     '<li class="swiper-slide js-item" data-platform="'+item.platforms[i].id+'">'
                        html +=         '<p><img srcset="'+item.platforms[i].img_light_x1+' 1x,' + item.platforms[i].img_light_x2+' 2x" src='+item.platforms[i].img_light_x1+' alt="" height="118" class="light" /><img srcset="'+item.platforms[i].img_dark_x1+' 1x,' + item.platforms[i].img_dark_x2+' 2x" src='+item.platforms[i].img_dark_x1+' alt="" height="118" class="dark" />'+item.platforms[i].title+'</p>'
                        html +=         '<div class="js-section">'
                        html +=             '<section>'
                        html +=                 '<h3>'+item.platforms[i].title+'</h3>'
                        html +=                 item.platforms[i].article
                        html +=             '</section>'
                        html +=         '</div>'
                        html +=     '</li>'
                    }
                    html +=     '</div>'
                    html += '</div>'

                    platforms_list.innerHTML = html
                    accordion_it(platforms_list);
                    info.platforms();
                } else {
                    platforms.style.display = 'none'
                }

                window.st ? window.st.refresh() : false;
            },

            dropdown: () => {
                if (mediaQueryList.matches) {
                    let _height,
                        _active_height = departments_list.querySelector('.is-active').clientHeight;
                    departments_list.clientHeight > _active_height ? _height = _active_height : _height = 'auto';

                    gsap.to(departments_list, {
                        height: _height,
                        duration: .25,
                        immediateRender: false,
                        ease: 'Power1.easeOut',
                        onUpdate: () => {
                            window.st ? window.st.refresh() : false;
                        },
                    })
                }
            },

            platforms: () => {
                const el = document.querySelector('.js-it_platforms');

                let item = el.querySelectorAll('.js-item'),
                    mediaQuery = '(min-width: 1025px)',
                    mediaQueryList = window.matchMedia(mediaQuery),
                    tl, stopit = true, swiper, allow_click = true;

                const toggle = function(ev, section, op) {
                    tl = gsap.timeline({clearProps:"all"})
                        .to(section, {
                            height: ev,
                            duration: .25,
                            ease: Power1.easeOut,
                            opacity: op,
                            onComplete: () => {
                                /*
    setTimeout(function() {
                                    swiper.update();
                                }, 600)
    */
                            }
                        })
                }

                const showIt = (event) => {
                    if (event.propertyName === 'width') {
                        swiper.update();

                        let _that = event.currentTarget;
                        if (dep__id === 2) {
                            if (event.propertyName ==='width') {
                                if (!stopit) {
                                    toggle('auto', _that.getElementsByClassName('js-section')[0], 1);
                                }
                            }
                        } else {
                            if (!stopit) {
                                toggle('auto', _that.getElementsByClassName('js-section')[0], 1);
                                swiper.slideTo(swiper.clickedIndex);
                                allow_click = true;
                            }
                        }
                    }
                }

                const action = (e) => {
                    const _that = e.currentTarget,
                        _height = 0;

                    if (allow_click === true) {
                        allow_click = false;
                        document.documentElement.removeAttribute('platform');

                        if (_that.classList.contains('is-rollout')) {
                            stopit = true;
                            _that.classList.remove('is-rollout');
                            _that.classList.remove('is-active');
                            _that.removeEventListener("transitionend", showIt);
                            toggle(_height, _that.getElementsByClassName('js-section')[0], 0);

                            if (document.documentElement.dataset.department === 'id-2') {
                                el.classList.remove('flex-allow')
                                faq.style.display = 'none';
                            }

                            allow_click = true;

                        } else {
                            let active_item = el.querySelector('.is-rollout');

                            if (active_item !== null) {
                                active_item.classList.remove('is-rollout');
                                active_item.classList.remove('is-active');
                                active_item.removeEventListener("transitionend", showIt);
                                toggle(_height, active_item.getElementsByClassName('js-section')[0], 0);
                            }

                            stopit = false;
                            _that.classList.add('is-rollout');
                            _that.classList.add('is-active');
                            _that.addEventListener("transitionend", showIt);

                            if (document.documentElement.dataset.department === 'id-2') {
                                el.classList.add('flex-allow');
                                faq.style.display = 'block';
                                document.documentElement.dataset.platform = 'platform-' + _that.dataset.platform;
                            }
                        }
                    }
                };

                const start_desktop = () => {
                    let slides = el.querySelectorAll('.swiper-slide'),
                        slides_per_view = 'auto', allow_touch_move = true;

                    for( let i = 0; i < item.length; i ++ ) {
                        item[i].addEventListener('click', action);
                    }

                    if (slides.length === 2) {
                        slides_per_view = 'auto';
                        allow_touch_move = false;
                    }

                    slides.length > 2 ? window.follow() : false;

                    swiper = new Swiper(el, {
                        modules: [FreeMode, Mousewheel],
                        slidesPerView: slides_per_view,
                        speed: 500,
                        allowTouchMove: allow_touch_move,
                        centeredSlidesBounds: true,

                        on: {
                            init: function() {
                                if (window.erecruterApp) {

                                    const slideItemEl = document.querySelector('.o-it_department.is-active');
                                    if(slideItemEl && slideItemEl.dataset.id === '1') {
                                        window.erecruterApp.it_department_page = 'Platformy Digital';
                                        window.erecruterApp.reloadPagination();
                                    }

                                }
                            }
                        },
                    });
                }

                const destroy = () => {
                    tl ? tl.kill(1,1) : false;
                    swiper.destroy();

                    for( let i = 0; i < item.length; i ++ ) {
                        item[i].removeEventListener('click', action);
                        item[i].querySelector('.js-section').removeAttribute('style')
                    }
                }

                mediaQueryList.addEventListener('change', event => {
                    event.matches ? start_desktop() : destroy();
                })

                mediaQueryList.matches ? start_desktop() : false;
            },

            reviews_carousel: () => {
                const swiper = new Swiper(document.querySelector('.js-it_department_reviews'), {
                    modules: [Navigation, Pagination],
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    },
                    on: {
                        init: function() {
                            if (this.slides.length < 2) {
                                el.querySelector('.o-pagination').style.display = 'none'
                            }
                        }
                    },
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                    },
                    speed: 500,
                });
            },

            show: (e) => {
                data.forEach(item => {
                    if (item.id === e.dataset.id) {
                        if (!e.classList.contains('is-active')) {
                            document.querySelector('#it_department') ? document.querySelector('#it_department').remove() : false;

                            for (let i = 0; i < departments.length; i++) {
                                departments[i].classList.remove('is-active');

                                if (departments[i].getAttribute('listener')) {
                                    departments[i].removeEventListener('click', info.dropdown)
                                    departments[i].removeAttribute('listener');
                                }
                            }

                            e.classList.add('is-active')
                            info.parse(item);
                            helpers.toggle('auto', description, 1, 0)

                            //if (mediaQueryList.matches) {
                            if (loaded === true) {
                                document.querySelector('.c-it_departments__list').scrollIntoView({ behavior: "smooth", block: "start" })
                            }

                            e.addEventListener('click', info.dropdown)
                            e.setAttribute('listener', true);
                        }
                    }
                })
            },
        }

        description ? helpers.getJSON(window.it_app ? window.it_app.departaments : 'json/departments.json') : false;
    }

    if (el) {
        const queryString = window.location.search;
        if (queryString) {
            const urlParams = new URLSearchParams(queryString),
                dep_id = urlParams.get('dep_id');

            dep_id != null ? init(dep_id) : false;
        } else {
            // mediaQueryList.matches ? init(2) : init(1);
            mediaQueryList.matches ? init(el.dataset.mobile) : init(el.dataset.desktop);
        }
    }

}, false)
