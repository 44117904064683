import Swiper, { FreeMode, Navigation, Lazy, Pagination } from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/modules/lazy/lazy.scss';
document.addEventListener('DOMContentLoaded', function() {

    const el = document.querySelector('#cinemaCarousel');
    
    const debounce = function(func, time){
        var time = time || 100;
        var timer;
        return function(event){
            if(timer) clearTimeout(timer);
            timer = setTimeout(func, time, event);
        };
    }

    const init = () => {
        const thisIndex = function(elm) {
            let nodes = elm.parentNode.childNodes, node, i = 0, count = 0;
            while( (node = nodes.item(i++)) && node != elm ) {
                if( node.nodeType == 1 ) count++;            
            }
        
            return count;
        };


        const showData = (err, data) => {
            if (err === null) {   
                let html = '<div class="swiper-wrapper">';                

                data.forEach(function(item) {
                    html += '<div class="swiper-slide">';
                    html += '<figure class="o-box" onclick="cinema.open(this, true)" data-id="' +item.id+'">';                        
                    html += '<img src="'+item.thumbnail_hi+'" alt="" width="294" height="400" />';
                    html += '<figcaption>'
                    html += '<p><strong>' + item.name + ',</strong> '+ item.position + '</p>'
                    html += '</figcaption>'
                    html += '</figure>'
                    html += '</div>'
                });
                html += '</div>';
                el.querySelector('.js-cinemaSwiper').innerHTML = html;
                
                setTimeout(function() {
                    carousel( el.querySelector('.js-cinemaSwiper') );                        
                }, 1000);
            } else {
                if (err === 500) {
                    console.log('cinema carousel error: ' +err);
                }
            }
        }
        
        const carousel = (carousel) => {
            let nav = true, swiper, status = false;
            
            const start = function() {
                swiper = new Swiper(carousel, {
            	    modules: [Lazy, Navigation, Pagination],
            	    centeredSlides: true,
                    loop: true,
                    slidesPerView: 'auto',
                    spaceBetween: 0,
                    speed: 500,
                    breakpoints: {
                        768: {
                            centeredSlides: false,
                        }
                    },
                    navigation: {
                        nextEl: carousel.parentNode.querySelector('.swiper-button-next'),
                        prevEl: carousel.parentNode.querySelector('.swiper-button-prev')
                    },
                    pagination: {
                        el: document.querySelector('#cinemaCarousel').querySelector('.swiper-pagination'),
                        clickable: true,
                    },
        	    });
        	    status = true;
            }
            
            const toggle = function() {
                start();  
                
                if (window.innerWidth > 768) {
                    if ( carousel.querySelectorAll('.swiper-slide').length <= 3 ) {
                        carousel.querySelector('.swiper-wrapper').classList.add('is-center');
                    }
                } else {
                    carousel.querySelector('.swiper-wrapper').classList.remove('is-center');
                }
                
                if ( carousel.querySelectorAll('.swiper-slide').length <= 4 ) {
                    document.getElementById('cinemaCarousel').querySelector('.swiper-button-next').style.display = 'none';
                    document.getElementById('cinemaCarousel').querySelector('.swiper-button-prev').style.display = 'none';
                }
            }            
            
            toggle();
        }
        
        const getJSON = (callback, payload) => {
            (async () => {
                payload === null ? payload = '' : false;
                
                const rawResponse = await fetch(process.env.API_MOVIES_URL, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(payload)
                });
                const content = await rawResponse.json();
                
                callback(null, content);
            })();                
        }
        
        el.dataset.category ? window.cinemaCategory = el.dataset.category : false;
        el.dataset.category ? getJSON(showData, {"categories": el.dataset.category}) : false;
    }
        
    el ? init() : false;

}, false);
