import { gsap, TweenMax, Power3 } from "gsap";
import Plyr from 'plyr';
import Cookies from 'js-cookie';
require('../../node_modules/plyr/dist/plyr.css');

document.addEventListener('DOMContentLoaded',function() {
    let _this;

    window.videoWidget  = {
        init: () => {
            _this = window.videoWidget;

            window.widgetPlayer = new Plyr('#player_widget', {
                vimeo: { 
                    sidedock: 0, 
                    controls: 0,
                },
                controls: ['play', 'progress', 'volume', 'mute', 'play-large']
            });
            
            widgetPlayer.source = {
                type: 'video',
                sources: [
                    {
                        src: document.querySelector('.js-videowidget').dataset.url,
                        provider: 'vimeo',
                    },
                ],
            };
            
            _this.methods();

/*
            if(Cookies.get('leroy-merlin-kariera-videowidget') === 'hide') {
                document.querySelector('.js-videowidget').classList.remove('is-visible');
                _this.toggle(0, document.querySelector('.js-videowidget').querySelector('.js-content'), 0);                
            } 
*/ 
        },
        
        methods: () => {
            document.querySelector('.js-videowidget').querySelector('.js-avatar').addEventListener('click', () => {
                document.querySelector('.js-videowidget').classList.add('is-visible');
                
                if (document.querySelector('.js-videowidget').classList.contains('avatar-only')) {
                    document.querySelector('.js-videowidget').classList.remove('avatar-only')
                    Cookies.set("leroy-merlin-kariera-videowidget", 'hide', { expires: 356, path: '/' });
                } else {
                    setTimeout(function() {
                        _this.toggle('auto', document.querySelector('.js-videowidget').querySelector('.js-content'), 1);
                    }, 250)
                }            
            });
    
/*
            document.querySelector('.js-videowidget').querySelector('.js-close').addEventListener('click', () => { 
                document.querySelector('.js-videowidget').classList.add('avatar-only');
                Cookies.set("leroy-merlin-kariera-videowidget", 'close', { expires: 356, path: '/' });
            });
*/
    
            document.querySelector('.js-videowidget').querySelector('.js-hide').addEventListener('click', () => {
                document.querySelector('.js-videowidget').classList.remove('is-visible');
                _this.toggle(0, document.querySelector('.js-videowidget').querySelector('.js-content'), 0);
                Cookies.set("leroy-merlin-kariera-videowidget", 'hide', { expires: 356, path: '/' });
            });
        },
        
        toggle: (ev, section, op) => {
            const tl = gsap.timeline();
            
            if (document.querySelector('.js-videowidget').classList.contains('is-visible')) {

                tl.call(function() {
                    document.querySelector('.js-videowidget').classList.add('bar-hide');
                })
        
                tl.call(function() {
                    document.querySelector('.js-videowidget').classList.add('rollout');
                })
    
                Cookies.set("leroy-merlin-kariera-videowidget", '', { expires: 356, path: '/' });

                tl.to(section, .5, {
                    height: ev,
                    opacity: op,
                    immediateRender: false,
                    ease: Power3.easeOut
                }, '+=.25')

            } else {
                widgetPlayer.pause();

                tl.to(section, .5, {
                    height: ev,
                    opacity: op,
                    immediateRender: false,
                    ease: Power3.easeOut
                })

                tl.call(function() {
                    document.querySelector('.js-videowidget').classList.remove('bar-hide');
                })
        
                tl.call(function() {
                    document.querySelector('.js-videowidget').classList.remove('rollout');
                })
            }
        }
    };
    
}, false);
