import Cookies from 'js-cookie';

document.addEventListener('DOMContentLoaded', () => {
    const el = document.querySelectorAll('.js-pageMode');
    
    const init = () => {
        for (let i = 0; i < el.length; i++) {
            el[i].querySelector('input').addEventListener('change', (e)=> {
                document.body.classList.toggle('light-mode');
                document.body.classList.toggle('dark-mode');
                Cookies.get('lmit-mode') ? Cookies.remove('lmit-mode') : Cookies.set("lmit-mode", 1);
            })
        }
    }

    if (Cookies.get('lmit-mode')) {
        document.body.classList.add('light-mode');
        for (let i = 0; i < el.length; i++) {
            el[i].querySelector('input').checked = true;
        }
    } else {
        document.body.classList.remove('light-mode');
        document.body.classList.add('dark-mode');
    }
    
    el.length > 0 ? init() : false;

}, false);

