export default function (){
    return [
        {
            name: 'Remonty i wykończenia',
            list: [
                'Montaż kuchni',
                'Wykończenie łazienki',
                'Montaż drzwi',
                'Montaż podłóg',
                'Montaż wyposażenia łazienki',
                'Montaż zabudowy z płyt karton-gips',
                'Montaż płytek',
                'Dekoracyjne wykończenie ścian oraz sufitu',
                'Montaż okien',
                'Montaż markiz',
                'Montaż ocieplenia',
                'Montaż garderoby, mebli na wymiar, mebli wolnostojących',
            ]
        },
        {
            name: 'Ogród',
            list: [
                'Układanie kostki brukowej i płyt tarasowych',
                'Montaż ogrodzenia',
                'Montaż architektury ogrodowej i domki drewniane',
                'Montaż mebli ogrodowych',
                'Montaż deski tarasowej',
            ]
        },
        {
            name: 'Technika i serwis',
            list: [
                'Montaż klimatyzacji',
                'Montaż pomp ciepła',
                'Montaż podgrzewaczy ciepłej wody użytkowej',
                'Montaż kotłów centralnego ogrzewania',
                'Montaż bramy garażowej',
                'Montaż rekuperacji',
            ]
        },
    ];
}
