import Plyr from 'plyr';
require('../../node_modules/plyr/dist/plyr.css');

document.addEventListener('DOMContentLoaded', () => {
    if (document.querySelector('#player_offer')) {
        const player = new Plyr('#player_offer', {
            controls: ['play', 'progress', 'volume', 'mute', 'play-large']
        });
    }
}, false);
