document.addEventListener('DOMContentLoaded',function() {
    
    const el = document.getElementsByClassName('js-stickybar')[0];

    const init = function() {
        const btn = el.getElementsByTagName('li');
        
        const action = function(e) {            
            for (let i = 0; i < btn.length;  i++) {
                btn[i].classList.remove('is-active');
            }

            window.runScroll(e.currentTarget.dataset.action);

            // console.clear();
            // console.log(e.currentTarget);

            e.currentTarget.classList.add('is-active');
        }
        
        for (let i = 0; i < btn.length;  i++) {
            btn[i].addEventListener('click', action);
        }
    };
    
    el ? init() : false;

}, false);
